import {useAtom} from 'jotai'
import {usePlausible} from 'next-plausible'

import {useMarkerColors} from '@/atoms/colors'
import {endAtom, startAtom} from '@/atoms/location'
import useMessages from '@/hooks/use-messages'
import useEventCallback from '@/hooks/use-event-callback'

import Geocoder from './geocoder'

import type {LabeledCoord, PlausibleEvents} from '@/types'

/**
 * This component is used to search for locations and set the start and end.
 * @returns
 */
export default function GeocodeSearch() {
  const [end, setEnd] = useAtom(endAtom)
  const [start, setStart] = useAtom(startAtom)
  const m = useMessages()
  const colors = useMarkerColors()
  const plausible = usePlausible<PlausibleEvents>()

  const onStartChange = useEventCallback((location: LabeledCoord) => {
    plausible('geocodeSearch', {props: {type: 'start'}})
    setStart(location.label, location.value)
  })

  const onEndChange = useEventCallback((location: LabeledCoord) => {
    plausible('geocodeSearch', {props: {type: 'end'}})
    setEnd(location.label, location.value)
  })

  return (
    <div className='flex w-full flex-col gap-2'>
      <Geocoder
        hexColor={colors.start.formatHex()}
        label={m('Geocoding.StartLabel')}
        onChange={onStartChange}
        placeholder={m('Geocoding.StartPlaceholder')}
        step='Start'
        value={start}
      />
      <Geocoder
        hexColor={colors.end.formatHex()}
        label={m('Geocoding.EndLabel')}
        onChange={onEndChange}
        placeholder={m('Geocoding.EndPlaceholder')}
        step='End'
        value={end}
      />
    </div>
  )
}
