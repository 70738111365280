import {Coord, GridDimensions} from '@/types'

/**
 * Pixel conversions and constants taken from
 * https://wiki.openstreetmap.org/wiki/Slippy_map_tilenames#Implementations
 */

/**
 * Pixels per tile.
 */
export const PIXELS_PER_TILE = 256

// 2^z represents the tile number. Scale that by the number of pixels in each tile.
function zScale(z: number): number {
  return Math.pow(2, z) * PIXELS_PER_TILE
}

// Converts from degrees to radians
function toRadians(degrees: number): number {
  return (degrees * Math.PI) / 180
}

// Converts from radians to degrees.
function toDegrees(radians: number): number {
  return (radians * 180) / Math.PI
}

/**
 * Convert a longitude to it's pixel value given a `zoom` level.
 */
function longitudeToPixel(longitude: number, zoom: number): number {
  return ((longitude + 180) / 360) * zScale(zoom)
}

/**
 * Convert a latitude to it's pixel value given a `zoom` level.
 */
function latitudeToPixel(latitude: number, zoom: number): number {
  const latRad: number = toRadians(latitude)
  return (
    ((1 - Math.log(Math.tan(latRad) + 1 / Math.cos(latRad)) / Math.PI) / 2) *
    zScale(zoom)
  )
}

/**
 * Maximum Latitude for valid Mercator projection conversion.
 */
const MAX_LAT = toDegrees(Math.atan(Math.sinh(Math.PI)))

function toPixel(c: Coord, z: number): Coord {
  if (c[1] > MAX_LAT || c[1] < -MAX_LAT) {
    throw new Error(
      'Pixel conversion only works between ' +
        MAX_LAT +
        'N and -' +
        MAX_LAT +
        'S'
    )
  }
  return [longitudeToPixel(c[0], z), latitudeToPixel(c[1], z)]
}

/**
 * Convert a pixel to it's longitude value given a zoom level.
 */
function pixelToLongitude(x: number, zoom: number): number {
  return (x / zScale(zoom)) * 360 - 180
}

/**
 * Convert a pixel to it's latitude value given a zoom level.
 */
function pixelToLatitude(y: number, zoom: number): number {
  const latRad: number = Math.atan(
    Math.sinh(Math.PI * (1 - (2 * y) / zScale(zoom)))
  )
  return toDegrees(latRad)
}

export function fromPixel(c: Coord, z: number): Coord {
  return [pixelToLongitude(c[0], z), pixelToLatitude(c[1], z)]
}

/**
 * Project a coordinate to it's pixel coordinate and find the appropriate point
 * associated with it.
 */
function coordinateToPoint(c: Coord, d: GridDimensions): Coord {
  const pixel = toPixel(c, d.zoom)
  return [Math.round(pixel[0] - d.west), Math.round(pixel[1] - d.north)]
}

export function isCoordinateWithinGrid(c: Coord, d: GridDimensions): boolean {
  const point = coordinateToPoint(c, d)
  return (
    point[0] >= 0 && point[0] < d.width && point[1] >= 0 && point[1] < d.height
  )
}

/**
 * Convert a coordinate to an index
 */
export function coordinateToIndex(c: Coord, d: GridDimensions): number {
  const point = coordinateToPoint(c, d)
  return point[0] + point[1] * d.width
}

/**
 * Project a pixel to a coordinate
 */
export function projectPixelToCoord(grid: GridDimensions, c: Coord) {
  return fromPixel([c[0] + grid.west, c[1] + grid.north], grid.zoom)
}
