import {useAtom, useSetAtom} from 'jotai'
import {usePlausible} from 'next-plausible'

import {activeRouteIndexAtom} from '@/atoms/ui'
import useMessages, {useMessageList} from '@/hooks/use-messages'

import type {PlausibleEvents} from '@/types'
import {transitModeIndexAtom} from '@/atoms/scenarios'

export default function SelectTransitModes() {
  const setActiveRouteIndex = useSetAtom(activeRouteIndexAtom)
  const [index, setIndex] = useAtom(transitModeIndexAtom)
  const m = useMessages('SelectTransitModes')
  const modes = useMessageList('SelectTransitModes.Modes')
  const plausible = usePlausible<PlausibleEvents>()

  const checkedClass: React.HTMLAttributes<HTMLDivElement>['className'] =
    'bg-blue-600 shadow'
  const uncheckedClass: React.HTMLAttributes<HTMLDivElement>['className'] = ''

  return (
    <div>
      <label className='font-medium text-white'>{m('Label')}</label>
      <div className='mt-1 flex justify-between gap-0.5 rounded bg-slate-700 p-1 shadow-inner'>
        {modes.map((label, i) => (
          <div
            className={`flex-1 cursor-pointer rounded px-2 py-1 text-center text-xs uppercase text-white transition-all hover:bg-blue-700 hover:shadow active:bg-blue-700 active:shadow-inner ${
              i === index ? checkedClass : uncheckedClass
            }`}
            key={i}
            onClick={() => {
              plausible('alternateTimeSelected', {props: {index: i}})
              setActiveRouteIndex([0, 0])
              setIndex(i)
            }}
          >
            {label}
          </div>
        ))}
      </div>
    </div>
  )
}
