export const USE_CACHE = process.env.NEXT_PUBLIC_USE_CACHE === 'true'

export function handleFetchError(res: Response) {
  if (res.ok === false) {
    console.error(
      `Error fetching ${res.url} >> [${res.status}]: ${res.statusText}`
    )
    throw res
  }
}

export function createClientCacheableUrl(url: string): string {
  return USE_CACHE ? `/api/resource/${encodeURIComponent(url)}` : url
}

/**
 * Fetch a resource, directly in production or via the resource cache if running locally.
 * @param url
 * @returns
 */
export async function clientFetchCacheableResource(url: string) {
  const res = await fetch(url, {cache: 'force-cache'})
  handleFetchError(res)
  return res
}
