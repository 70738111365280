import {useMemo} from 'react'

import {createClientCacheableUrl} from '@/utils/client-fetch'

import type {Scenario} from '@/types'
import Head from 'next/head'

const MAPBOX_API_URL = 'https://api.mapbox.com'

export function PrefetchTransitiveData({scenarios}: {scenarios: Scenario[]}) {
  const urls = useMemo(
    () =>
      scenarios.map((s) =>
        createClientCacheableUrl(`${s.urls[0]}/transitive.json`)
      ),
    [scenarios]
  )
  return (
    <Head>
      {urls.map((url) => (
        <link key={url} rel='prefetch' href={url} />
      ))}
    </Head>
  )
}

export function PrefetchMapboxApi() {
  return (
    <Head>
      <link
        key='mapbox-dns-preconnect'
        rel='preconnect'
        href={MAPBOX_API_URL}
        crossOrigin=''
      />
      <link
        key='mapbox-dns-prefetch'
        rel='dns-prefetch'
        href={MAPBOX_API_URL}
      />
    </Head>
  )
}
