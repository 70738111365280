import {forwardRef} from 'react'

const colors = {
  neutral: 'border-slate-900/10 bg-slate-100 hover:bg-slate-200 text-slate-900',
  primary: 'border-blue-900/10 bg-blue-600 hover:bg-blue-700 text-white',
  delete: 'border-red-900/10 bg-red-600 hover:bg-red-700 text-white'
}

const sizes = {
  sm: 'px-2 py-1 text-sm',
  md: 'px-4 py-2 text-base'
}

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  React.PropsWithChildren<{
    color?: 'primary' | 'neutral' | 'delete'
    grow?: boolean
    size?: 'sm' | 'md'
  }>

const Button = forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  {children, color = 'neutral', grow = false, size = 'md', ...buttonProps},
  ref
) {
  const colorClasses = colors[color]
  const sizeClasses = sizes[size]
  const growClass = grow ? 'flex-grow' : ''
  return (
    <button
      className={`flex transition-all ${growClass} items-center justify-center rounded border ${colorClasses} ${sizeClasses} gap-2 drop-shadow-sm hover:drop-shadow active:shadow-inner`}
      tabIndex={0}
      ref={ref}
      {...buttonProps}
    >
      {children}
    </button>
  )
})

export default Button
