import {atom, useAtomValue} from 'jotai'

export const activeRouteIndexAtom = atom<[number, number]>([0, 0])
const activeFetchesAtom = atom<number>(0)
export const adjustFetchesAtom = atom<null, [number], void>(
  null,
  (get, set, by) => {
    set(activeFetchesAtom, get(activeFetchesAtom) + by)
  }
)
export const isLoadingAtom = atom<boolean>((get) => get(activeFetchesAtom) > 0)
export const timeCutoffAtom = atom<number>(30)

export function useIsLoading() {
  return useAtomValue(isLoadingAtom)
}
