import {useSetAtom} from 'jotai'
import Cookies from 'js-cookie'
import {useEffect} from 'react'

import {userAtom} from '@/atoms/user'

export default function useInitUser() {
  const setUser = useSetAtom(userAtom)
  useEffect(() => {
    if (typeof window === 'undefined') return

    const userString = Cookies.get('taui.user')
    if (userString) {
      setUser(JSON.parse(userString))
    }
  }, [setUser])
}
