// Vendored from https://github.com/Volune/use-event-callback
import {useLayoutEffect, useMemo, useRef} from 'react'

type Fn<ARGS extends any[], R> = (...args: ARGS) => R

// Default to an empty `useEffect` on the server
const useEffect = typeof window === 'undefined' ? () => null : useLayoutEffect

export default function useEventCallback<A extends any[], R>(
  fn: Fn<A, R>
): Fn<A, R> {
  const ref = useRef<Fn<A, R>>(fn)
  useEffect(() => {
    ref.current = fn
  })
  return useMemo(
    () =>
      (...args: A): R => {
        const {current} = ref
        return current(...args)
      },
    []
  )
}
