import {atom} from 'jotai'

import {coordinateToIndex} from '@/utils/coordinate-to-point'
import getScenarioBbox from '@/utils/scenario-bbox'

import {endAtom, startAtom} from './location'

import type {Scenario} from '@/types'

export const scenariosAtom = atom<Scenario[]>([])

export const timeOfDayIndexAtom = atom(0)
export const transitModeIndexAtom = atom(0)

export const scenarioVariantIndexAtom = atom(
  (get) => get(timeOfDayIndexAtom) + get(transitModeIndexAtom) * 4
)

export const startCoordinateIndexesAtom = atom<number[]>((get) => {
  const {value: start} = get(startAtom)
  const scenarios = get(scenariosAtom)
  if (start == null) return scenarios.map(() => -1)
  return scenarios.map((scenario) => coordinateToIndex(start, scenario))
})

export const endCoordinateIndexesAtom = atom<number[]>((get) => {
  const end = get(endAtom)
  const scenarios = get(scenariosAtom)
  const endCoord = end.value
  if (endCoord == null) return scenarios.map(() => -1)
  return scenarios.map((scenario) => coordinateToIndex(endCoord, scenario))
})

export const scenariosBboxAtom = atom((get) => {
  const scenarios = get(scenariosAtom)
  return scenarios.map((s) => getScenarioBbox(s))
})
