import Link from 'next/link'
import {useRouter} from 'next/router'
import {CiGlobe} from 'react-icons/ci'

import Button, {ButtonProps} from './button'

const locales = {
  en: 'English',
  es: 'Español'
}

export default function LocaleSelector(props: ButtonProps) {
  const router = useRouter()
  const {pathname, asPath, query, locale} = router
  const newLocale = locale === 'es' ? 'en' : 'es'
  return (
    <Link
      as={asPath}
      href={{pathname, query}}
      locale={newLocale}
      legacyBehavior
    >
      <Button {...props}>
        {locales[newLocale]}
        <CiGlobe />
      </Button>
    </Link>
  )
}
