import {useSetAtom} from 'jotai'
import {usePlausible} from 'next-plausible'
import {useCallback} from 'react'

import useIsMobile from '@/hooks/use-is-mobile'
import {
  walkthroughOpenAtom,
  walkthroughStepAtom,
  walkthroughTotalStepsAtom
} from '@/atoms/walkthrough'
import {PlausibleEvents} from '@/types'

export default function useStartWalkthrough() {
  const plausible = usePlausible<PlausibleEvents>()
  const setOpen = useSetAtom(walkthroughOpenAtom)
  const setStep = useSetAtom(walkthroughStepAtom)
  const isMobile = useIsMobile()
  const setTotalSteps = useSetAtom(walkthroughTotalStepsAtom)

  return useCallback(() => {
    plausible('walkthroughStarted')
    setOpen(true)
    setStep(1)
    if (isMobile) {
      setTotalSteps((totalSteps) => totalSteps - 1)
    }
  }, [isMobile, plausible, setOpen, setStep, setTotalSteps])
}
