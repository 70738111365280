import {Tooltip, TooltipContent, TooltipTrigger} from './tooltip'

export function TextTooltip({
  children,
  tooltip
}: React.PropsWithChildren<{tooltip: string}>) {
  return (
    <Tooltip>
      <TooltipTrigger
        as='span'
        className='border-b border-dashed border-slate-900/40'
      >
        {children}
      </TooltipTrigger>
      <TooltipContent className='z-50 max-w-md rounded border border-slate-900/10 bg-white px-4 py-2 text-lg shadow'>
        {tooltip}
      </TooltipContent>
    </Tooltip>
  )
}
