import omit from 'lodash/omit'

import {Coord} from '@/types'

export const MAPBOX_GEOCODING_URL =
  'https://api.mapbox.com/geocoding/v5/mapbox.places'

type Context = {
  id: string
  text: string
}

type Options = Record<string, string | number | boolean>

export interface MapboxFeature extends GeoJSON.Feature<GeoJSON.Point> {
  center: [number, number] // lon/lat
  context: Context[]
  place_name: string
  text: string
}

/**
 * Format URL for fetching with query parameters
 */
function formatURL(text: string, opts: Options) {
  const queryParams = Object.keys(opts)
    .map((k) => `${k}=${opts[k]}`)
    .join('&')
  return `${MAPBOX_GEOCODING_URL}/${text}.json?${queryParams}`
}

/**
 * Create an action that dispatches the given action on success.
 *
 * NB Content-Type is application/vnd.geo+json
 */
export async function geocode(
  text: string,
  opts: Options
): Promise<MapboxFeature[]> {
  const res = await fetch(
    formatURL(text, {
      ...opts,
      access_token: process.env.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN ?? ''
    })
  )
  if (res.ok === false) {
    throw new Error(`Error while geocoding > [${res.status}] ${res.statusText}`)
  }
  const geojson = await res.json()
  return geojson.features
}

export const reverseGeocode = (c: Coord, opts: Options) =>
  geocode(`${c[0]},${c[1]}`, omit(opts, 'limit'))
