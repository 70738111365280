import {useSetAtom} from 'jotai'
import {useRouter} from 'next/router'
import {useEffect} from 'react'

import {endAtom, startAtom} from '@/atoms/location'

function strToCoord(s: string): [number, number] {
  const [lng, lat] = s.split(',')
  return [parseFloat(lng), parseFloat(lat)]
}

/**
 * Set the start and end locations on page load from the URL query parameters.
 */
export default function useInitLocation() {
  const setStart = useSetAtom(startAtom)
  const setEnd = useSetAtom(endAtom)
  const {isReady} = useRouter()

  useEffect(() => {
    if (!isReady) return
    const urlParams = new URLSearchParams(window.location.search)
    const start = urlParams.get('start')
    const end = urlParams.get('end')
    if (start !== null) {
      setStart(null, strToCoord(start))
    }
    if (end !== null) {
      setEnd(null, strToCoord(end))
    }
  }, [setEnd, setStart, isReady])
}
