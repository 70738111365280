import {useHydrateAtoms} from 'jotai/utils'

import {configAtom} from '@/atoms/config'
import {scenariosAtom} from '@/atoms/scenarios'
import {endAtom, startAtom} from '@/atoms/location'
import {destinationConfigsAtom} from '@/atoms/grids'
import {timeCutoffAtom} from '@/atoms/ui'
import Application from '@/components/application'
import {
  PrefetchTransitiveData,
  PrefetchMapboxApi
} from '@/components/prefetch-links'
import useInitLocation from '@/hooks/use-init-location'
import {dictionaries} from '@/langs'
import loadConfig from '@/load-config' // This file can only be loaded server side.
import getCenterFromScenario from '@/utils/scenario-center'
import {serverFetchCacheableJson} from '@/utils/server-fetch'

import type {GetStaticProps, InferGetStaticPropsType} from 'next'
import type {Config, Messages, Scenario} from '@/types'
import useInitUser from '@/hooks/use-init-user'

// Load this data once on build. Eventually allow for generation via [params]
export const getStaticProps: GetStaticProps<{
  config: Config
  locale: string
  messages: Messages
  scenarios: Scenario[]
  pointsOfInterest: unknown
}> = async (ctx) => {
  const config = await loadConfig()

  const pointsOfInterest = config.poiUrl
    ? await serverFetchCacheableJson(config.poiUrl)
    : null
  const scenarios: Scenario[] = []

  for (let i = 0; i < config.scenarioConfigs.length; i++) {
    const scenarioConfig = config.scenarioConfigs[i]
    // TODO parse with Zod
    const request = await serverFetchCacheableJson(
      `${scenarioConfig.urls[0]}/request.json`
    )
    scenarios.push({
      ...scenarioConfig,
      ...request,
      center: getCenterFromScenario(request)
    })
  }

  const locale = ctx.locale === 'es' ? 'es' : 'en'
  const messages: Messages = await dictionaries[locale]()

  return {
    props: {
      config,
      locale,
      messages,
      pointsOfInterest,
      scenarios
    }
  }
}

/**
 * Wrap the application file after loading the grids.
 */
export default function IndexPage(
  props: InferGetStaticPropsType<typeof getStaticProps>
) {
  useInitLocation()
  useInitUser()
  useHydrateAtoms([
    [configAtom, props.config],
    [destinationConfigsAtom, props.config.destinationConfigs],
    [scenariosAtom, props.scenarios],
    [endAtom, props.config.end],
    [startAtom, props.config.start],
    [timeCutoffAtom, props.config.defaultTimeCutoff]
  ])
  return (
    <>
      <PrefetchMapboxApi />
      <PrefetchTransitiveData scenarios={props.scenarios} />
      <Application />
    </>
  )
}
