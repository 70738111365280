import {FaInfoCircle} from 'react-icons/fa'

export default function RouteCardInfo({children}: React.PropsWithChildren<{}>) {
  return (
    <div className='flex w-full items-center justify-center gap-3 p-3 last:rounded-b'>
      <span className='w-4 text-center'>
        <FaInfoCircle className='text-blue-500' />
      </span>
      <span className='flex-1'>{children}</span>
    </div>
  )
}
