import {Suspense} from 'react'
import {ErrorBoundary} from 'react-error-boundary'

import Loader from './loader'

function ErrorAlert() {
  return (
    <div className='m-1 max-w-md rounded bg-red-700 px-4 py-2 font-medium text-white'>
      Error: check console
    </div>
  )
}

export function CellLoader() {
  return (
    <div className='flex h-full w-full justify-center p-6'>
      <Loader />
    </div>
  )
}

export default function Cell({
  children,
  fallback
}: React.PropsWithChildren<{
  fallback?: React.ReactNode
}>) {
  return (
    <ErrorBoundary
      fallback={<ErrorAlert />}
      onError={(e, i) => console.error('Error rendering cards:', e, i)}
    >
      <Suspense fallback={fallback !== undefined ? fallback : <CellLoader />}>
        {children}
      </Suspense>
    </ErrorBoundary>
  )
}
